import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import sh from "bundles/common/utils/sh";
import { organizeCategoriesByDepartment } from "bundles/common/utils/helpers";

const Expertise = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const [organizedCategories, setOrganizedCategories] = useState({});

  const financeCategories = organizedCategories["Finance"] || [];
  const financeCategoryCodes = financeCategories.map(category => category.code).join("%2C");
  const techCategories = organizedCategories["Technology"] || [];
  const techCategoryCodes = techCategories.map(category => category.code).join("%2C");
  const hrCategories = organizedCategories["HR"] || [];
  const hrCategoryCodes = hrCategories.map(category => category.code).join("%2C");

  const fetchCategories = () => {
    sh.get("/categories", {
      authenticity_token: authenticityToken,
    }).then((res) => {
      const fetchedCategories = res?.data?.categories;
      setOrganizedCategories(organizeCategoriesByDepartment(fetchedCategories));
    });
  };

  const data = {
    label: intl.formatMessage({ id: "expertise.label" }),
    title: `${intl.formatMessage({ id: "expertise.the" })} <br/><span class="transition-colors duration-1000 ease-linear [[data-current-color=green]_&]:text-softGreen [[data-current-color=blue]_&]:text-deepBlue [[data-current-color=orange]_&]:text-orange">${intl.formatMessage({ id: "expertise.power" })}</span> ${intl.formatMessage({ id: "expertise.knowledge" })}`,
    cards: [
      {
        color: "green",
        title: intl.formatMessage({ id: "expertise.cards.finance.title" }),
        content: intl.formatMessage({ id: "expertise.cards.finance.content" }),
        button: {
          url: `/${i18nLocale}/jobs?categories=${financeCategoryCodes}`,
          title: intl.formatMessage({ id: "expertise.cards.finance.button" })
        }
      },
      {
        color: "orange",
        title: intl.formatMessage({ id: "expertise.cards.technology.title" }),
        content: intl.formatMessage({ id: "expertise.cards.technology.content" }),
        button: {
          url: `/${i18nLocale}/jobs?categories=${techCategoryCodes}`,
          title: intl.formatMessage({ id: "expertise.cards.technology.button" })
        }
      },
      {
        color: "orange",
        title: intl.formatMessage({ id: "expertise.cards.hr.title" }),
        content: intl.formatMessage({ id: "expertise.cards.hr.content" }),
        button: {
          url: `/${i18nLocale}/jobs?categories=${hrCategoryCodes}`,
          title: intl.formatMessage({ id: "expertise.cards.hr.button" })
        }
      }
    ]
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <section
      id="expertise"
      data-current-color={data.cards[0].color}
      className="relative text-darkGreen vw-py [--py:50] mini:[--py:120] laptop:[--py:160] !overflow-x-clip [&[data-current-color=green]]:bg-lightGreen [&[data-current-color=blue]]:bg-lightBlue [&[data-current-color=orange]]:bg-lightBeige transition-colors duration-1000 ease-linear"
    >
      <div id="expertise-container" className="gg-container">
        <div id="expertise-grid" className="gg-grid">
          <div id="expertise-heading_wrapper" className="relative col-start-1 col-span-12 mini:col-start-1 mini:col-span-9 tablet:col-start-3">
            <div id="expertise-heading_sticky_holder" className="mini:sticky mini:top-[40%] tablet:top-[35%] laptop:top-[30%] flex flex-col vw-gap [--gap:16] mini:[--gap:35]">
              <div
                id="experise-heading-labels"
                className="max-tablet:hidden flex flex-col items-center justify-center vw-gap [--gap:10] absolute top-1/2 vw-left [--left:-37] laptop:[--left:-60] desktop:[--left:-70] transform -translate-x-full -translate-y-[45%]"
              >
                {data.cards.map((card, index) => (
                  <div
                    key={index}
                    id="expertise-heading-label"
                    data-index={index}
                    className="inline-flex items-center justify-center text-center vw-px [--px:18] vw-py [--py:10] border rounded-full  bg-transparent border-darkGreen text-darkGreen [&.selected]:text-white [&.selected]:bg-darkGreen  uppercase vw-text [--text:12] leading-none vw-tracking [--tracking:2.5] [writing-mode:vertical-rl] [text-orientation:mixed] transform rotate-180 transition-colors duration-150 ease-out-cubic"
                  >
                    {card.title}
                  </div>
                ))}
              </div>
              <h3
                id="expertise-heading-label"
                className="transition-colors duration-1000 ease-linear [[data-current-color=green]_&]:text-softGreen [[data-current-color=blue]_&]:text-deepBlue [[data-current-color=orange]_&]:text-orange uppercase subtitle fadeInUp"
                dangerouslySetInnerHTML={{ __html: data.label }}
              />
              <div
                id="expertise-heading-title"
                className="h1 fadeInUp"
                dangerouslySetInnerHTML={{ __html: data.title }}
              />
            </div>
          </div>
          <div id="expertise-cards_wrapper" className="col-start-1 col-span-12 mini:col-start-11 mini:col-span-14 tablet:col-start-13 tablet:col-span-12 laptop:col-start-14 laptop:col-span-11 flex flex-col vw-gap [--gap:40]">
            {data.cards.map((card, index) => (
              <div
                key={index}
                id="expertise-card"
                className="bg-white flex flex-col vw-gap [--gap:36] vw-px [--px:16] mini:[--px:28] laptop:[--px:56] vw-py [--py:60] tablet:[--py:70] vw-rounded-br [--rounded-br:100] tablet:[--rounded-br:150] laptop:[--rounded-br:200] fadeInUp"
                data-index={index}
                data-color={card.color}
              >
                {(card.color === "green") && (
                  <svg id="expertise-card-icon" className="vw-w [--w:48] vw-h [--h:41]" viewBox="0 0 48 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 32.5449C0 28.1266 3.58172 24.5449 8 24.5449H12.4932V40.1098H0V32.5449Z" fill="#B2E3DB" />
                    <path d="M17.7534 20.5713C17.7534 16.153 21.3351 12.5713 25.7534 12.5713H30.2466V40.1092H17.7534V20.5713Z" fill="#B2E3DB" />
                    <path d="M35.5068 7.99999C35.5068 3.58172 39.0886 0 43.5068 0H48V40.1096H35.5068V7.99999Z" fill="#4CC1AE" />
                  </svg>
                )}
                {(card.color === "orange" && (index == 1)) && (
                  <svg id="expertise-card-icon" className="vw-w [--w:48] vw-h [--h:41]" viewBox="0 0 48 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 32.5449C0 28.1266 3.58172 24.5449 8 24.5449H12.4932V40.1098H0V32.5449Z" fill="#e39f74" />
                    <path d="M17.7534 20.5713C17.7534 16.153 21.3351 12.5713 25.7534 12.5713H30.2466V40.1092H17.7534V20.5713Z" fill="#e0a077" />
                    <path d="M35.5068 7.99999C35.5068 3.58172 39.0886 0 43.5068 0H48V40.1096H35.5068V7.99999Z" fill="#d57e48" />
                  </svg>
                )}
                {(card.color === "orange" && (index == 2)) && (
                  <svg id="expertise-card-icon" className="vw-w [--w:48] vw-h [--h:41]" viewBox="0 0 48 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 32.5449C0 28.1266 3.58172 24.5449 8 24.5449H12.4932V40.1098H0V32.5449Z" fill="#f7ab40" />
                    <path d="M17.7534 20.5713C17.7534 16.153 21.3351 12.5713 25.7534 12.5713H30.2466V40.1092H17.7534V20.5713Z" fill="#f7a128" />
                    <path d="M35.5068 7.99999C35.5068 3.58172 39.0886 0 43.5068 0H48V40.1096H35.5068V7.99999Z" fill="#F69206" />
                  </svg>
                )}
                <h4 id="expertise-card-title" className="h2 fadeInUp">
                  {card.title}
                </h4>
                <div
                  id="expertise-card-content"
                  className="body richtext fadeInUp"
                  dangerouslySetInnerHTML={{ __html: card.content }}
                />
                {card.button && (
                  <div id="expertise-card-button" className="fadeInUp">
                    <a
                      href={card.button.url}
                      className="button max-mini:w-full [[data-color=green]_&]:border-lightGreen [[data-color=green]_&]:bg-lightGreen [[data-color=green]_&]:text-darkGreen [[data-color=green]_&]:hover:border-softGreen [[data-color=green]_&]:hover:bg-softGreen [[data-color=green]_&]:hover:text-white [[data-color=blue]_&]:border-lightBlue [[data-color=blue]_&]:bg-lightBlue [[data-color=blue]_&]:text-darkGreen [[data-color=blue]_&]:hover:border-deepBlue [[data-color=blue]_&]:hover:bg-deepBlue [[data-color=blue]_&]:hover:text-white [[data-color=orange]_&]:border-lightBeige [[data-color=orange]_&]:bg-lightBeige [[data-color=orange]_&]:text-darkGreen [[data-color=orange]_&]:hover:border-orange [[data-color=orange]_&]:hover:bg-orange [[data-color=orange]_&]:hover:text-white"
                    >
                      <svg className="button-icon" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.40009 1.09796L6.80213 6.5L1.40009 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <span className="button-text">
                        {card.button.title}
                      </span>
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Expertise;
