import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, AppBar, Toolbar, IconButton, Typography, Menu, Avatar, Tooltip, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import { adminMenuEntries, userMenuEntries } from "bundles/common/utils/constants";
import SanderLogoWhite from "internal/sander-logo-white.svg";

const useStyles = makeStyles((theme) => ({
  navbar: {
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    paddingLeft: "32px",
    paddingRight: "32px"
  },
  logo: {
    width: "100px",
  }
}));

const Navbar = () => {
  const currentUser = useSelector((state) => state.current_user);
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const classes = useStyles();
  const navigate = useNavigate();
  const settings = currentUser?.["admin?"]
    ? adminMenuEntries : userMenuEntries;

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting) => {
    if (setting === "Logout") {
      logout();
    } else if (settings.includes(setting)) {
      if (setting === "Dashboard") {
        navigate("internal");
      }
      else {
        navigate(`internal/${setting.toLowerCase()}`);
      }
    }

    setAnchorElUser(null);
  };

  const logout = () => {
    sh.delete("users/sign_out", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        authenticity_token: authenticityToken,
      },
    })
      .then(() => {
        window.location.href = `${window.location.href.split("internal")[0]}internal`;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <AppBar position="static">
      <Toolbar disableGutters className={classes.navbar}>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/internal"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <img className={classes.logo} src={SanderLogoWhite} />
        </Typography>

        <Typography
          variant="h5"
          noWrap
          component="a"
          href=""
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <img className={classes.logo} src={SanderLogoWhite} />
        </Typography>

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt={`${currentUser?.first_name} ${currentUser?.last_name}`} src={currentUser?.avatar_url} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
